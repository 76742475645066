import { Card, CardHeader, CardBody, Flex, Text, Tooltip } from "@chakra-ui/react";
import { PracticeProfileRedFlag } from "__generated__/graphql";

interface PracticeProfileProps {
  practiceProfileRedFlags: PracticeProfileRedFlag[];
}
export function PracticeProfile({ practiceProfileRedFlags }: PracticeProfileProps) {
  return (
    <Card w="100%" p="16px" overflow="scroll">
      <CardHeader lineHeight="115%" fontWeight="bold" p="0px 8px 16px 0px">
        <Flex justifyContent="space-between">
          <Text>Practice Profile</Text>
        </Flex>
      </CardHeader>
      <CardBody p="0">
        <Flex w="100%" justifyContent="space-between" gap="12px">
          <Card
            border="1px solid"
            borderColor="gray.200"
            boxShadow="none"
            w="100%"
            height="max-content"
          >
            <CardBody p="0">
              <Flex direction="column">
                {practiceProfileRedFlags.map((result, index) => {
                  return (
                    <Flex
                      direction="column"
                      key={index}
                      backgroundColor={index % 2 === 0 ? "gray.100" : "white"}
                      borderTopRadius={index === 0 ? "3px" : "0"}
                      p="8px"
                    >
                      <Tooltip label={result?.explanation} aria-label="A tooltip" hasArrow>
                        {result?.term}
                      </Tooltip>
                    </Flex>
                  );
                })}
                {practiceProfileRedFlags.length === 0 && (
                  <Flex direction="column" borderTopRadius="3px" padding="8px">
                    <Text fontSize="sm">No Practice Profile Red Flags Found</Text>
                  </Flex>
                )}
              </Flex>
            </CardBody>
          </Card>
        </Flex>
      </CardBody>
    </Card>
  );
}
