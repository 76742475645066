import { Flex, Text } from "@chakra-ui/react";
import { IoLockClosed } from "react-icons/io5";

export function Banner() {
  return (
    <Flex
      w="100%"
      zIndex="5"
      mt="64px"
      position="fixed"
      background="yellow.200"
      p="8px 32px"
      alignItems="center"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        h="20px"
        w="20px"
        borderRadius="100%"
        bgColor="yellow.500"
        mr="12px"
      >
        <IoLockClosed size="12px" color="white" />
      </Flex>
      <Text fontWeight="bold">Temporarily Locked: </Text>
      <Text fontWeight="normal" ml="6px">
        This submission is temporarily locked because it is in the S2Q queue.
      </Text>
    </Flex>
  );
}
