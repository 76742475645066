import { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
  leftSideContent?: ReactNode;
  rightSideContent?: ReactNode;
  leftBig: boolean;
  rightBig: boolean;
  header?: ReactNode;
}

export function Layout({
  children,
  leftSideContent,
  rightSideContent,
  leftBig,
  rightBig,
  header,
}: LayoutProps) {
  return (
    <>
      {header !== undefined && (
        <div
          style={{
            width: "100%",
          }}
        >
          {header}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        {leftSideContent !== undefined && (
          <div
            style={{
              width: leftBig ? "200px" : "50px",
              transition: "all 0.2s ease-in-out",
            }}
          >
            {leftSideContent}
          </div>
        )}
        <div
          style={{
            flex: 1,
            width: "100px",
            overflow: "scroll",
            marginTop: "60px",
          }}
        >
          {children}
        </div>
        {rightSideContent !== undefined && (
          <div
            style={{
              width: rightBig ? "331px" : "46px",
              transition: "all 0.2s ease-in-out",
            }}
          >
            {rightSideContent}
          </div>
        )}
      </div>
    </>
  );
}
