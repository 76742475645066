var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_RISK_DETAILS } from "mutations";
import { GET_SUBMISSION } from "queries";
export function useModelRatingApi() {
    const { id } = useParams();
    const [updateRiskDetails] = useMutation(UPDATE_RISK_DETAILS, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    });
    const [loading, setLoading] = useState(false);
    const fetchModelRating = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield updateRiskDetails({ variables: { submissionId: id } });
        }
        catch (e) {
            console.error("Error updating risk details", e);
        }
        finally {
            setLoading(false);
        }
    }), [updateRiskDetails, id]);
    return { loading, fetchModelRating };
}
