import { Icon } from "@chakra-ui/react";

export function SubmissionIcon() {
  return (
    <Icon mr="-5px" overflow="visible" viewBox="0 0 24 24" boxSize="24px" p="1px">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4268 2.14148V15.2686C15.4268 16.5781 13.892 16.5781 13.0873 16.5781H3.95969C3.24582 16.5781 2.66699 15.9888 2.66699 15.2686V2.14148C2.66699 1.41801 3.24261 0.832031 3.95327 0.832031H14.1406C14.8512 0.832031 15.4268 1.41801 15.4268 2.14148ZM9.5 5.24902C9.5 4.97288 9.27614 4.74902 9 4.74902C8.72386 4.74902 8.5 4.97288 8.5 5.24902V9.04192L7.27022 7.81214C7.07496 7.61687 6.75838 7.61687 6.56311 7.81214C6.36785 8.0074 6.36785 8.32398 6.56311 8.51924L8.64645 10.6026C8.84171 10.7978 9.15829 10.7978 9.35355 10.6026L11.4369 8.51924C11.6321 8.32398 11.6321 8.0074 11.4369 7.81214C11.2416 7.61687 10.925 7.61687 10.7298 7.81214L9.5 9.04192V5.24902ZM5.75 10.249C5.75 9.97288 5.52614 9.74902 5.25 9.74902C4.97386 9.74902 4.75 9.97288 4.75 10.249V11.9157C4.75 12.2693 4.89048 12.6085 5.14052 12.8585C5.39057 13.1085 5.72971 13.249 6.08333 13.249H11.9167C12.2703 13.249 12.6094 13.1085 12.8595 12.8585C13.1095 12.6085 13.25 12.2693 13.25 11.9157V10.249C13.25 9.97288 13.0261 9.74902 12.75 9.74902C12.4739 9.74902 12.25 9.97288 12.25 10.249V11.9157C12.25 12.0041 12.2149 12.0889 12.1524 12.1514C12.0899 12.2139 12.0051 12.249 11.9167 12.249H6.08333C5.99493 12.249 5.91014 12.2139 5.84763 12.1514C5.78512 12.0889 5.75 12.0041 5.75 11.9157V10.249Z"
        fill="currentColor"
      />
    </Icon>
  );
}
