import { Icon } from "@chakra-ui/react";

export function PolicyIcon() {
  return (
    <Icon mr="3px" overflow="visible" viewBox="0 0 24 24" boxSize="24px" p="1px">
      <path
        d="M11.4764 12.2748L14.6495 10.6519C14.8906 10.5291 15.1575 10.4669 15.426 10.4538L15.4268 3.14148C15.4268 2.41801 14.8512 1.83203 14.1406 1.83203H3.95327C3.24261 1.83203 2.66699 2.41801 2.66699 3.14148V16.2686C2.66699 16.9888 3.24582 17.5781 3.95969 17.5781H11.0873C10.5101 16.1254 10.4184 14.7021 10.4128 14.0426C10.4072 13.2905 10.8147 12.6136 11.4764 12.2748ZM5.96556 4.32635H8.2792C8.63454 4.32635 8.92234 4.61933 8.92234 4.98107C8.92234 5.34281 8.63454 5.63579 8.2792 5.63579H5.96556C5.61021 5.63579 5.32242 5.34281 5.32242 4.98107C5.32242 4.61933 5.60941 4.32635 5.96556 4.32635ZM5.96556 7.47551H12.138C12.4934 7.47551 12.7812 7.76849 12.7812 8.13023C12.7812 8.49198 12.4934 8.78496 12.138 8.78496H5.96556C5.61021 8.78496 5.32242 8.49198 5.32242 8.13023C5.32242 7.76849 5.60941 7.47551 5.96556 7.47551ZM5.96797 11.9341C5.61263 11.9341 5.32483 11.6411 5.32483 11.2794C5.32483 10.9177 5.61263 10.6247 5.96797 10.6247H11.2594C11.6147 10.6247 11.9025 10.9177 11.9025 11.2794C11.9025 11.6411 11.6147 11.9341 11.2594 11.9341H5.96797Z"
        fill="currentColor"
      />
      <path
        d="M18.9776 13.4468L15.8045 11.8239C15.6229 11.7306 15.4074 11.7306 15.2257 11.8239L12.0526 13.4468C11.834 13.5589 11.6949 13.784 11.6973 14.0328C11.7077 15.2645 12.0213 18.9259 15.2925 20.1274C15.4332 20.179 15.5964 20.179 15.7379 20.1274C19.0091 18.926 19.3226 15.2645 19.3331 14.0328C19.3355 13.784 19.1963 13.5581 18.9776 13.4468ZM17.2942 15.4633L15.4492 17.3424C15.3238 17.4701 15.159 17.5339 14.9942 17.5339C14.8294 17.5339 14.6646 17.4701 14.5392 17.3424L13.7353 16.524C13.4837 16.2686 13.4837 15.8545 13.7353 15.5984C13.9869 15.3422 14.3929 15.3422 14.6445 15.5984L14.9934 15.9536L16.3842 14.5377C16.6359 14.2816 17.0418 14.2816 17.2935 14.5377C17.5451 14.7922 17.5451 15.2072 17.2942 15.4633Z"
        fill="currentColor"
      />
    </Icon>
  );
}
