import { Icon } from "@chakra-ui/react";

export function ResourcesIcon() {
  return (
    <Icon mr="-5px" overflow="visible" viewBox="0 0 24 24" boxSize="24px" p="1px">
      <path
        d="M10.3415 2.16294L16.4114 5.23261C16.7772 5.4176 16.7772 5.93999 16.4114 6.12499L10.3415 9.19466C10.1996 9.2664 10.0321 9.2664 9.89022 9.19466L3.82033 6.12499C3.45452 5.93999 3.45452 5.41761 3.82033 5.23261L9.89022 2.16294C10.0321 2.09119 10.1996 2.09119 10.3415 2.16294Z"
        fill="currentColor"
      />
      <path
        d="M10.7983 18.3731V10.7927C10.7983 10.6042 10.9044 10.4317 11.0726 10.3466L17.3915 7.15009C17.7241 6.98185 18.1172 7.22354 18.1172 7.59625V14.9723C18.1172 15.1563 18.0162 15.3254 17.8541 15.4126L11.5353 18.8133C11.2022 18.9926 10.7983 18.7513 10.7983 18.3731Z"
        fill="currentColor"
      />
      <path
        d="M2.11719 14.9726V7.59652C2.11719 7.2238 2.5103 6.98211 2.84288 7.15035L9.16174 10.3468C9.32999 10.4319 9.43605 10.6044 9.43605 10.793V18.3733C9.43605 18.7516 9.0322 18.9929 8.69909 18.8136L2.38023 15.4129C2.21822 15.3257 2.11719 15.1565 2.11719 14.9726Z"
        fill="currentColor"
      />
    </Icon>
  );
}
