import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { parse, isAfter } from "date-fns";
import {
  Box,
  Select,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Text,
  IconButton,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Grid,
  GridItem,
  Spinner,
  Badge,
  useDisclosure,
  Checkbox,
} from "@chakra-ui/react";
import {
  CheckIcon,
  CopyIcon,
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  Controller,
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form";
import { INDIGO_SPECIALTIES, PROVIDER_SUFFIXES, STATES } from "app-constants";
import { AddressOption, AddressInputField, DatePicker, SelectArrow } from "components";
import {
  ProviderUpdateInput,
  InputMaybe,
  AddressInput,
  SubmissionUpdateInput,
  Limit,
  LimitType,
} from "__generated__/graphql";
import { formatLimit, addressToString, formatErrors } from "utils";
import { useNpiSearch } from "hooks";
import { GoTriangleDown } from "react-icons/go";

interface ProviderFormProps {
  isDisabled?: boolean;
  address?: InputMaybe<AddressInput>;
  setValue?: UseFormSetValue<SubmissionUpdateInput>;
  index: number;
  lengthOfSet: number;
  register: UseFormRegister<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  control: Control<SubmissionUpdateInput>;
  onChangeSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
  deleteProvider?: () => void;
  duplicateProvider?: () => void;
  provider?: ProviderUpdateInput;
  saveComponent?: ReactNode;
  clearErrors: UseFormClearErrors<SubmissionUpdateInput>;
  forwardRef?: (el: HTMLDivElement) => void;
  isSelected?: boolean;
  effectiveDate?: InputMaybe<string>;
  handleOpen?: () => void;
  isSaving?: boolean;
}

export function ProviderForm({
  isDisabled,
  address,
  setValue,
  errors,
  index,
  lengthOfSet,
  register,
  control,
  onChangeSelect,
  deleteProvider,
  duplicateProvider,
  provider,
  saveComponent,
  clearErrors,
  forwardRef: ref,
  effectiveDate,
  isSelected,
  isSaving,
}: ProviderFormProps) {
  const e = errors?.providers?.[index];
  const { isLoading, matches, fetchMatches } = useNpiSearch();
  const [loadedMatches, setLoadedMatches] = useState(false);
  const { onOpen, isOpen, onToggle } = useDisclosure({ defaultIsOpen: lengthOfSet <= 5 });

  const [selectedAddress, setSelectedAddress] = useState<AddressOption | null>(() => {
    if (address) {
      return {
        label: addressToString(address),
        value: address,
      };
    }
    return null;
  });

  useEffect(() => {
    if (!isOpen && isSelected) {
      onOpen();
    }
    // Disabling needing `isOpen` in the dependency array because
    // I do not want to force it to be open if it is closed locally
    // even if it is still technically selected.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, onOpen]);

  useEffect(() => {
    if (!provider?.firstName) {
      onOpen();
    }
  }, [provider, onOpen]);

  useEffect(() => {
    if (isLoading) {
      setLoadedMatches(true);
    }
    if (loadedMatches) {
      const timeout = setTimeout(() => setLoadedMatches(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [loadedMatches, isLoading]);

  useEffect(() => {
    if (selectedAddress === null && address) {
      setSelectedAddress({
        label: addressToString(address),
        value: address,
      });
    }
  }, [selectedAddress, address, setSelectedAddress]);
  useEffect(() => {
    if (e) {
      onOpen();
    }
  }, [e, onOpen]);

  useEffect(() => {
    if (matches?.length === 1) {
      const match = matches[0];
      setValue?.(`providers.${index}.nppesCity`, match.city);
      setValue?.(`providers.${index}.nppesState`, match.state);
      setValue?.(`providers.${index}.nppesSpecialty`, match.specialty);
    }
  }, [matches, index, setValue]);

  return (
    <div ref={ref} style={{ paddingTop: "16px" }}>
      <Card
        as={Button}
        onClick={onToggle}
        w="100%"
        aria-label="expand provider review card"
        variant="ghost"
        alignItems="start"
        justifyContent="flex-start"
        h="80px"
        m="0px"
        p="20px"
        style={{
          boxShadow:
            "1px 1px 3px 0px rgba(0, 0, 0, 0.10), 1px 1px 3px 0px rgba(0, 0, 0, 0.10) !important",
          display: isOpen ? "none" : "flex",
        }}
        _hover={{ background: "white" }}
      >
        <CardHeader p="0" w="100%">
          <Flex p="0" w="100%" justifyContent="space-between">
            <Flex direction="column" textAlign="left">
              <Text fontSize="xm" color="gray.400" fontWeight="normal">
                Individual Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `- ${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontWeight="700" fontSize="md" p="0">
                {`${provider?.firstName || ""} ${provider?.lastName || ""}`}{" "}
              </Text>
            </Flex>
            <Flex gap="16px">
              {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
              <Box w="40px" h="40px" pt="6px" pl="2px">
                <ChevronDownIcon fontSize="1.5rem" />
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
      </Card>
      <Card
        style={{
          display: isOpen ? "flex" : "none",
        }}
        bgColor="white"
        borderRadius="3px"
        variant="outline"
        pb="15px"
      >
        <CardHeader pb="0">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              direction="column"
              alignItems="flex-start"
              _hover={{ background: "transparent" }}
              backgroundColor="transparent"
              textAlign="left"
              w="100%"
              as={Button}
              p="0"
              m="0"
              onClick={onToggle}
            >
              <Text fontSize="xm" color="gray.400" fontWeight="normal">
                Individual Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `- ${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontWeight="700" fontSize="md" p="0">
                {`${provider?.firstName || ""} ${provider?.lastName || ""}`}{" "}
              </Text>
            </Flex>
            <Flex gap="16px">
              {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
              {deleteProvider !== undefined && isOpen && (
                <Button
                  isDisabled={isDisabled}
                  onClick={deleteProvider}
                  variant="solid"
                  bgColor="indigo.100"
                  color="indigo.500"
                  aria-label="Delete provider"
                  m="0px"
                  rightIcon={<DeleteIcon fontSize="0.8rem" />}
                >
                  Delete
                </Button>
              )}
              {duplicateProvider !== undefined && isOpen && (
                <Button
                  isDisabled={isDisabled}
                  onClick={duplicateProvider}
                  variant="solid"
                  bgColor="indigo.100"
                  color="indigo.500"
                  aria-label="Copy provider"
                  m="0px"
                  rightIcon={<CopyIcon />}
                >
                  Duplicate
                </Button>
              )}
              {!isOpen ? (
                <IconButton
                  onClick={onToggle}
                  variant="ghost"
                  aria-label="Expand provider"
                  fontSize="1.5rem"
                  m="0px"
                  icon={<ChevronDownIcon />}
                />
              ) : (
                <IconButton
                  onClick={onToggle}
                  variant="ghost"
                  aria-label="Collapse provider"
                  fontSize="1.5rem"
                  m="0px"
                  icon={<ChevronUpIcon />}
                />
              )}
            </Flex>
          </Flex>
        </CardHeader>

        <Collapse in={isOpen} animateOpacity style={{ overflow: "visible" }}>
          {isOpen && (
            <>
              <CardBody pt="15px" pb="0px">
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
                  gap="12px"
                >
                  <GridItem colSpan={4}>
                    <FormControl isInvalid={!!e?.firstName?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.firstName`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        First Name *
                      </FormLabel>
                      <Input
                        isDisabled={isDisabled}
                        id={`providers.${index}.firstName`}
                        data-cy={`providers.${index}.firstName`}
                        type="text"
                        {...register(`providers.${index}.firstName`, {
                          required: "First name is required",
                        })}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.firstName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <FormControl isInvalid={!!e?.lastName?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.lastName`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        Last Name *
                      </FormLabel>
                      <Input
                        isDisabled={isDisabled}
                        id={`providers.${index}.lastName`}
                        data-cy={`providers.${index}.lastName`}
                        type="text"
                        {...register(`providers.${index || 0}.lastName`, {
                          required: "Last name is required",
                        })}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.lastName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel
                        htmlFor={`providers.${index}.suffix`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        Suffix
                      </FormLabel>
                      <Select
                        isDisabled={isDisabled}
                        id={`providers.${index}.suffix`}
                        size="md"
                        icon={<SelectArrow />}
                        {...register(`providers.${index}.suffix`)}
                        onChange={onChangeSelect || register(`providers.${index}.suffix`).onChange}
                      >
                        <option></option>
                        {Object.keys(PROVIDER_SUFFIXES).map((spec) => (
                          <option key={spec} value={spec}>
                            {PROVIDER_SUFFIXES[spec]}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Controller
                      rules={{
                        required: "Retro date is required",
                        validate: (value) => {
                          if (effectiveDate && value) {
                            const parsedEffectiveDate = parse(
                              effectiveDate,
                              "MM/dd/yyyy",
                              new Date(),
                            );
                            if (isAfter(new Date(value), parsedEffectiveDate)) {
                              return "Retro date cannot be after effective date";
                            }
                          }
                          return true;
                        },
                      }}
                      render={({ fieldState, field }) => (
                        <FormControl isInvalid={Boolean(fieldState.error)}>
                          <FormLabel
                            htmlFor={`providers.${index}.retroDate`}
                            fontSize="md"
                            mb="0px"
                            fontWeight="normal"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Retro Date *
                          </FormLabel>
                          <DatePicker
                            disabled={isDisabled}
                            id={`providers.${index}.retroDate`}
                            data-cy={`providers.${index}.retroDate`}
                            invalid={Boolean(fieldState.error)}
                            selected={field?.value || ""}
                            onChange={(date: string) => {
                              clearErrors(`providers.${index}.retroDate`);
                              setValue?.(`providers.${index}.retroDate`, date);
                            }}
                          />
                          <FormErrorMessage>
                            <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                            {fieldState.error && fieldState.error.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                      control={control}
                      name={`providers.${index}.retroDate`}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
              <CardBody pt="12px" pb="0px">
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
                  gap="12px"
                >
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={!!e?.limit?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.limit`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Limit *
                      </FormLabel>
                      <Select
                        isDisabled={isDisabled}
                        id={`providers.${index}.limit`}
                        size="md"
                        icon={<SelectArrow />}
                        {...register(`providers.${index}.limit`, {
                          required: "Limit is required",
                        })}
                        onChange={(e) => {
                          clearErrors(`providers.${index}.limit`);
                          onChangeSelect?.(e) || register(`providers.${index}.limit`).onChange(e);
                        }}
                      >
                        {Object.values(Limit).map((limit) => {
                          return (
                            <option key={limit} value={limit}>
                              {formatLimit(limit)}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.limit?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={!!e?.limitType?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.limitType`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Limit Type *
                      </FormLabel>
                      <Select
                        isDisabled={isDisabled}
                        id={`providers.${index}.limitType`}
                        size="md"
                        icon={<SelectArrow />}
                        {...register(`providers.${index}.limitType`, {
                          required: "Limit type is required",
                        })}
                        onChange={(e) => {
                          clearErrors(`providers.${index}.limitType`);
                          onChangeSelect?.(e) ||
                            register(`providers.${index}.limitType`).onChange(e);
                        }}
                      >
                        {Object.values(LimitType)
                          .filter((limitType) => limitType !== LimitType.Shared)
                          .map((limitType) => {
                            return (
                              <option key={limitType} value={limitType}>
                                {limitType}
                              </option>
                            );
                          })}
                      </Select>
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.limitType?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl>
                      <FormLabel
                        htmlFor={`providers.${index}.specialty`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Broker Specialty
                      </FormLabel>
                      <Input
                        isDisabled={isDisabled}
                        id={`providers.${index}.specialty`}
                        type="text"
                        {...register(`providers.${index}.specialty`)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl isInvalid={!!e?.indigoSpecialty?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.indigoSpecialty`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Indigo Specialty *
                      </FormLabel>
                      <Select
                        isDisabled={isDisabled || isSaving || false}
                        id={`providers.${index}.indigoSpecialty`}
                        size="md"
                        icon={
                          isSaving ? (
                            <Spinner fontSize="1rem" color="indigo.500" />
                          ) : (
                            <SelectArrow />
                          )
                        }
                        {...register(`providers.${index}.indigoSpecialty`, {
                          required: "Specialty is required",
                        })}
                        onChange={(e) => {
                          clearErrors(`providers.${index}.indigoSpecialty`);
                          onChangeSelect?.(e) ||
                            register(`providers.${index}.indigoSpecialty`).onChange(e);
                        }}
                      >
                        <option></option>
                        {INDIGO_SPECIALTIES.map((spec) => (
                          <option key={spec.value} value={spec.name}>
                            {spec.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.indigoSpecialty?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel
                        htmlFor={`providers.${index}.npi`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        NPI
                      </FormLabel>
                      <InputGroup>
                        <Input
                          isDisabled={isDisabled}
                          id={`providers.${index}.npi`}
                          type="text"
                          {...register(`providers.${index}.npi`)}
                          onChange={(e) => {
                            if (e.target.value.length === 10) {
                              fetchMatches({ ...provider, npi: e.target.value });
                            } else {
                              setValue?.(`providers.${index}.nppesCity`, "");
                              setValue?.(`providers.${index}.nppesState`, "");
                              setValue?.(`providers.${index}.nppesSpecialty`, "");
                            }
                            register(`providers.${index}.npi`).onChange(e);
                          }}
                        />
                        {isLoading ? (
                          <InputRightElement>
                            <Spinner />
                          </InputRightElement>
                        ) : (
                          loadedMatches && (
                            <InputRightElement>
                              <CheckIcon fontSize="sm" color="green" />
                            </InputRightElement>
                          )
                        )}
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                </Grid>
              </CardBody>
              <CardBody pt="12px" pb="0px">
                <Grid
                  gap="12px"
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
                >
                  <GridItem colSpan={3}>
                    <Controller
                      rules={{ required: "Address is required" }}
                      render={({ fieldState }) => (
                        <FormControl isInvalid={Boolean(fieldState.error)}>
                          <FormLabel
                            htmlFor={`providers.${index}.address.streetName`}
                            fontSize="md"
                            mb="0px"
                            fontWeight="normal"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Street Address *
                          </FormLabel>
                          <AddressInputField
                            isDisabled={isDisabled}
                            isInvalid={Boolean(fieldState.error)}
                            id={`providers.${index}.address?.streetName`}
                            onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                              clearErrors(`providers.${index}.address`);
                              setValue?.(
                                `providers.${index}.address.streetName`,
                                e?.target?.value || "",
                              );
                            }}
                            onChange={(option: AddressOption | null) => {
                              clearErrors(`providers.${index}.address`);
                              setSelectedAddress(option);
                              setValue?.(`providers.${index}.address`, option?.value || null);
                              setValue?.(
                                `providers.${index}.address.city`,
                                option?.value?.city || "",
                              );
                              setValue?.(
                                `providers.${index}.address.state`,
                                option?.value?.state || "",
                              );
                              setValue?.(
                                `providers.${index}.address.zip`,
                                option?.value?.zip || "",
                              );
                              setValue?.(
                                `providers.${index}.address.secondary`,
                                option?.value?.secondary || "",
                              );
                              setValue?.(
                                `providers.${index}.address.streetName`,
                                option?.value?.streetName || "",
                              );
                            }}
                            value={selectedAddress}
                          />
                          <FormErrorMessage>
                            <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                            {fieldState.error && fieldState.error.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                      control={control}
                      name={`providers.${index}.address.streetName`}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel
                        htmlFor={`providers.${index}.address.secondary`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Address Line 2
                      </FormLabel>
                      <Input
                        isDisabled={isDisabled}
                        id={`providers.${index}.address.secondary`}
                        type="text"
                        {...register(`providers.${index}.address.secondary`)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Controller
                      name={`providers.${index}.address.city`}
                      rules={{ required: "City is required" }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl isInvalid={!!(fieldState.error && fieldState.error.message)}>
                          <FormLabel
                            htmlFor={`providers.${index}.address.city`}
                            fontSize="md"
                            mb="0px"
                            fontWeight="normal"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            City *
                          </FormLabel>
                          <Input
                            isDisabled={isDisabled}
                            id={`providers.${index}.address.city`}
                            type="text"
                            {...field}
                            value={field?.value || ""}
                          />
                          <FormErrorMessage>
                            <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                            {fieldState.error && fieldState.error.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={!!e?.address?.state?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.address.state`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        State *
                      </FormLabel>
                      <Select
                        isDisabled={isDisabled}
                        id={`providers.${index}.address.state`}
                        size="md"
                        icon={<GoTriangleDown />}
                        {...register(`providers.${index}.address.state`, {
                          required: "State is required",
                        })}
                        onChange={
                          onChangeSelect || register(`providers.${index}.address.state`).onChange
                        }
                      >
                        <option></option>
                        {STATES.map((state) => (
                          <option key={state.abbreviation} value={state.abbreviation}>
                            {state.abbreviation}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.address?.state?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl isInvalid={!!e?.address?.zip?.message}>
                      <FormLabel
                        htmlFor={`providers.${index}.address.zip`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Zip Code *
                      </FormLabel>
                      <Input
                        isDisabled={isDisabled}
                        id={`providers.${index}.address.zip`}
                        type="text"
                        {...register(`providers.${index}.address.zip`, {
                          required: "Zip code is required",
                        })}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.address?.zip?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
                  gap="12px"
                >
                  <GridItem colSpan={3}>
                    <FormControl>
                      <Controller
                        control={control}
                        name={`providers.${index}.partTimePractice`}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            pt="12px"
                            colorScheme="indigo"
                            isChecked={value === "3102"}
                            value={value || ""}
                            onChange={(e) => {
                              onChange(e.target.checked ? "3102" : "");
                            }}
                          >
                            <Text whiteSpace="nowrap" fontWeight="normal" mt="5px">
                              Part-Time Credit
                            </Text>
                          </Checkbox>
                        )}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={3}>
                    <FormControl>
                      <Controller
                        control={control}
                        name={`providers.${index}.newToPractice`}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            pt="12px"
                            colorScheme="indigo"
                            isChecked={value === 1}
                            value={value || 0}
                            onChange={(e) => {
                              onChange(e.target.checked ? 1 : 0);
                            }}
                          >
                            <Text whiteSpace="nowrap" fontWeight="normal" mt="5px">
                              New to Practice
                            </Text>
                          </Checkbox>
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <FormControl>
                      <Controller
                        control={control}
                        name={`providers.${index}.newPracticeProfile`}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            pt="12px"
                            colorScheme="indigo"
                            isChecked={!!value}
                            value={value === true ? "true" : "false"}
                            onChange={(e) => {
                              onChange(e.target.checked ? true : false);
                            }}
                          >
                            <Text whiteSpace="nowrap" fontWeight="normal" mt="5px">
                              New Practice Profile
                            </Text>
                          </Checkbox>
                        )}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
                {saveComponent}
              </CardBody>
            </>
          )}
        </Collapse>
      </Card>
    </div>
  );
}
