import { Container, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Banner } from "components";

interface SubnavProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  showBanner?: boolean;
}

export function Subnav({ showBanner, leftContent, rightContent }: SubnavProps) {
  return (
    <Flex direction="column">
      <Flex
        borderColor="gray.200"
        bgColor="white"
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
        borderBottom="0px"
        zIndex="10"
        position="fixed"
        w="100%"
      >
        <Container>
          <Flex h="64px" pl="16px" pr="64px" alignItems="center" justify="space-between">
            {leftContent}
            {rightContent}
          </Flex>
        </Container>
      </Flex>
      {showBanner && <Banner />}
    </Flex>
  );
}
