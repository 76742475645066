export const DEFAULT_MRS_VALUE = 0.8;
export const DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE = 1;
export const DEFAULT_EXCLUDED_NPI_MRS = 0.8;
export const DEFAULT_NEW_PRACTICE_PROFILE_MRS = 1;
export function isV2SrfAdjustment(state, countyFips) {
    if (state && state === "GA") {
        return false;
    }
    /*
    These counties are excluded from the V2 SRF adjustment:
    Cook County, Illinois: 17031
    Madison County, Illinois: 17099
    St. Clair County, Illinois: 17163
    Dade County, Florida: 12086
    West Palm Beach County, Florida: 12099
    Hillsborough County, Florida: 12057
    Philadelphia County, Pennsylvania: 42101
    Essex County, New Jersey: 34013
    Bronx County, New York: 36005
    Brooklyn County, New York: 36047
    Manhattan County, New York: 36061
    Queens County, New York: 36081
    Staten Island County, New York: 36085 */
    return ![
        17031, 17099, 17163, 12086, 12099, 12057, 42101, 34013, 36005, 36047, 36061, 36081, 36085,
    ].includes(parseFloat(countyFips !== null && countyFips !== void 0 ? countyFips : ""));
}
export function defaultMrsAdjustment(mrs) {
    if (mrs >= 0.35 && mrs <= 0.5) {
        console.log("V1: MRS is between 0.35 and 0.5");
        return 0;
    }
    if (mrs >= 0.372 && mrs <= 1.25) {
        console.log("V1: MRS is between 0.372 and 1.25");
        return -8;
    }
    if (mrs > 1.26) {
        console.log("V1: MRS is greater than 1.26");
        return 0;
    }
    if (mrs < 0.372) {
        console.log("V1: MRS is less than 0.372");
        return calcMrsAdjustment(mrs, 0.35);
    }
}
export function calcMrsAdjustment(mrs, target) {
    return parseInt(Math.abs(((mrs - target) / mrs) * 100).toFixed(2));
}
export function defaultMrsAdjustment_v2(mrs) {
    let target;
    if (mrs > 1.5) {
        return 0;
    }
    if (mrs > 0.5 && mrs <= 1.3) {
        console.log("V2: MRS is between 0.5 and 1.3");
        target = 0.625 * (mrs - 0.5) + 0.5;
    }
    else if (mrs < 0.372) {
        console.log("V2: MRS is less than 0.372");
        target = 0.35;
    }
    else if (mrs < 0.5 && mrs >= 0.372) {
        console.log("V2: MRS is between 0.372 and 0.5");
        target = mrs;
    }
    else {
        console.log("V2: MRS is greater than 1.3");
        target = 2.5 * (mrs - 1.3) + 1;
    }
    return parseInt((-1 * ((mrs - target) / mrs) * 100).toFixed(2));
}
