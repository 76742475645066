/**
 * @deprecated - use package @app-stack/constants instead
 */
export const DEFAULT_MRS_VALUE = 0.8;
/**
 * @deprecated - use package @app-stack/constants instead
 */
export const DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE = 1;
/**
 * @deprecated - use package @app-stack/constants instead
 */
export const AVERAGE_MRS_QUOTE_THRESHOLD = 1.35;
/**
 * @deprecated - use package @app-stack/constants instead
 */
export const AVERAGE_MRS_QUOTE_THRESHOLD_LARGE = 1.35;
