import { Menu, MenuButton, Button, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon, CheckIcon } from "@chakra-ui/icons";

interface CompleteReviewButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClickDecline?: () => void;
  onClickSubmit?: () => void;
  isAutomationInProgress?: boolean;
}
export function CompleteReviewButton({
  isDisabled,
  isLoading,
  onClickDecline,
  onClickSubmit,
  isAutomationInProgress,
}: CompleteReviewButtonProps) {
  return (
    <Menu matchWidth={true}>
      <MenuButton
        data-cy="complete-review-button"
        variant="solid"
        as={Button}
        mx="4px"
        w="182px"
        isDisabled={isAutomationInProgress || isDisabled}
        colorScheme="indigo"
        isLoading={isLoading}
        loadingText="Submitting"
        rightIcon={isDisabled ? <CheckIcon fontSize="xs" mt="5px" /> : <ChevronDownIcon mt="5px" />}
      >
        {isDisabled ? "Review Complete" : "Complete Review"}
      </MenuButton>
      <MenuList zIndex={2} w="40px">
        <MenuItem onClick={onClickDecline}>Decline</MenuItem>
        <MenuItem onClick={onClickSubmit} data-cy="submit-to-sunlight">
          Submit to Sunlight
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
