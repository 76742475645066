/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class IndigoError extends Error {
    constructor(message, statusCode) {
        super(message);
        this.statusCode = statusCode;
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class ValidationError extends IndigoError {
    constructor(message) {
        super(message, 400);
        this.name = "ValidationError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class InternalServerError extends IndigoError {
    constructor(message) {
        super(message, 500);
        this.name = "InternalServerError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class InvalidConfigurationError extends IndigoError {
    constructor(message) {
        super(message, 400);
        this.name = "InvalidConfigurationError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class DependencyError extends IndigoError {
    constructor(message) {
        super(message, 500);
        this.name = "DependencyError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class ThrottlingError extends IndigoError {
    constructor(message) {
        super(message, 429);
        this.name = "ThrottlingError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class InitializationError extends IndigoError {
    constructor(message) {
        super(message, 500);
        this.name = "InitializationError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class InvalidInputError extends IndigoError {
    constructor(message) {
        super(message, 400);
        this.name = "InvalidInputError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class NotFoundError extends IndigoError {
    constructor(message) {
        super(message, 404);
        this.name = "NotFoundError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class TransactionConflictError extends IndigoError {
    constructor(message) {
        super(message, 409);
        this.name = "TransactionConflictError";
    }
}
/**
 * @deprecated - use package @app-stack/errors_types instead
 */
export class LambdaInvokeError extends IndigoError {
    constructor(message) {
        super(message, 500);
        this.name = "LambdaInvokeError";
    }
}
